<template>
	<div class="mt-4 mt-lg-0 mb-4">
		<b-container v-if="appData.events && appData.events.data && appData.events.data.length === 0">
			<b-alert show class="d-flex justify-content-center mb-3">
				По вашему запросу ничего не найдено
			</b-alert>
		</b-container>
		<b-container v-else-if="error">
			<b-alert variant="danger" show class="d-flex justify-content-center mb-3">
				{{ error.message || error }}
			</b-alert>
		</b-container>
		<template v-else-if="appData.events">
			<component :is="cardComponentName" />

			<b-pagination
				v-if="appData.events.meta && appData.events.meta.last_page > 1"
				:value="page"
				:total-rows="appData.events.meta && appData.events.meta.total || 0"
				:per-page="appData.events.meta && appData.events.meta.per_page || 0"
				:limit="7"
				align="center"
				pills
				hide-goto-end-buttons
				next-class="pagination-next"
				prev-class="pagination-prev"
				prev-text=""
				next-text=""
				@input="setFilter('page', $event)"
			/>
		</template>
		<b-container v-else>
			<b-alert show class="d-flex justify-content-center mb-3">
				Нет данных
			</b-alert>
		</b-container>
	</div>
</template>

<script>
import ContentItems from '~/components/navigator-v2/body/content/ContentItems.vue';
import ContentItemsWithPrice from '~/components/navigator-v2/body/content/ContentItemsWithPrice.vue';
import MediaCard from '~/components/navigator-v2/body/content/MediaCard.vue';

export default {
	name: 'NavigatorContent',

	components: { ContentItems, ContentItemsWithPrice, MediaCard },

	inject: ['appData', 'groupData', 'setFilter'],

	props: {
		isWithPrices: {
			type: Boolean,
			default: false,
		},
		cardComponent: {
			type: String,
			default: null,
		}
	},

	computed: {
		page() {
			return this.$route.query.page ?? 1;
		},
		cardComponentName() {
			let returnData = '';

			if (this.cardComponent) {
				returnData = this.cardComponent;
			} else {
				returnData = this.isWithPrices ? 'content-items-with-price' : 'content-items';
			}

			return returnData;
		},
	},
};
</script>
