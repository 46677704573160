export default {
	data() {
		return {
			screenWidth: null,
		};
	},
	mounted() {
		this.setScreenWidth();
		window.addEventListener("resize", this.setScreenWidth);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.setScreenWidth);
	},
	methods: {
		setScreenWidth() {
			this.screenWidth = window.innerWidth;
		},
	},
};
