import { render, staticRenderFns } from "./NavigatorHeader.vue?vue&type=template&id=78fafe46&scoped=true"
import script from "./NavigatorHeader.vue?vue&type=script&lang=js"
export * from "./NavigatorHeader.vue?vue&type=script&lang=js"
import style0 from "./NavigatorHeader.vue?vue&type=style&index=0&id=78fafe46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78fafe46",
  null
  
)

export default component.exports